









































import { ISite } from '@/store/modules/sites/types'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { IDataLoaderData, ILoader } from './types'
import { IRfSensor } from '@/store/modules/rf_sensors/types'

const props = {
  showPercentage: {
    type: Boolean,
    default: true
  }
}

export default Vue.extend({
  name: 'DataLoader',
  props,
  data(): IDataLoaderData {
    return {
      currentLoader: null,
      loaderCount: 0
    }
  },
  async mounted(): Promise<void> {
    for (const loader of this.allLoaders) {
      this.currentLoader = loader
      await loader.callback()
      this.loaderCount += 1
    }
  },
  computed: {
    ...mapState('sensors', ['radarsSet']),
    ...mapGetters('system', ['assetsPath', 'useBranding']),
    ...mapGetters('users', ['theme']),
    ...mapState('users', ['user']),
    ...mapState('sites', ['sitesSet', 'activeSiteId']),
    ...mapGetters('sites', ['activeSite']),
    ...mapState(['timezonesList']),
    ...mapState('rf_sensors', ['rfSensorsSet', 'dsxSensorsSet']),
    siteCount(): number {
      return Object.keys(this.sitesSet || {}).length
    },
    currentProgress(): number {
      if (this.loaderCount === 0) return 0
      return Math.floor((this.loaderCount / this.allLoaders.length) * 100)
    },
    logoImg(): string {
      return require(`@/assets/${this.assetsPath}logo.svg`)
    },
    allLoaders(): ILoader[] {
      return [
        {
          id: 'clear_detections',
          label: 'Clear State',
          callback: () => {
            this.resetDetections()
          }
        },
        ...this.loaders,
        {
          id: 'complete',
          label: 'Complete',
          callback: () => {
            setTimeout(() => {
              this.$emit('complete')
            }, 750)
          }
        }
      ]
    },
    loaders(): ILoader[] {
      return [
        {
          id: 'time_zones',
          label: 'Time Zones',
          callback: this.fetchTimezones
        },
        {
          id: 'system_settings',
          label: 'System Settings',
          callback: this.FETCH_SYSTEM_SETTINGS
        },
        {
          id: 'sites',
          label: 'Sites',
          callback: this.fetchSites
        },
        {
          id: 'user_profile',
          label: 'User Profile',
          callback: this.setupUser
        },
        {
          id: 'user_data',
          label: 'User Data',
          callback: this.fetchUserData
        },
        {
          id: 'site_warnings',
          label: 'Site Warnings',
          callback: this.fetchSiteWarnings
        },
        {
          id: 'site_markers',
          label: 'Site Markers',
          callback: this.fetchSiteMarkers
        },
        {
          id: 'sentries',
          label: 'Sentries',
          callback: this.fetchSentries
        },
        {
          id: 'rf_sensors',
          label: 'Sensors',
          callback: this.fetchRfSensors
        },
        {
          id: 'radars',
          label: 'Sensors',
          callback: this.fetchRadars
        },
        {
          id: 'cameras',
          label: 'Sensors',
          callback: this.fetchCameras
        },
        {
          id: 'cannons',
          label: 'Sensors',
          callback: this.fetchCannons
        },
        {
          id: 'gps_compasses',
          label: 'Sensors',
          callback: this.fetchGpsCompasses
        },
        {
          id: 'discovair_sensors',
          label: 'Sensors',
          callback: this.fetchDiscovairs
        },
        {
          id: 'zmask_total',
          label: 'Radar Masks',
          callback: this.fetchZMaskTotal
        },
        {
          id: 'agl_mask_total',
          label: 'AGL Mask',
          callback: this.fetchAglMasks
        },
        {
          id: 'rf_filters',
          label: 'RF Filters',
          callback: this.fetchRfFilters
        },
        {
          id: 'init_map_layers',
          label: 'Map Layers',
          callback: this.initializeActiveMapLayers
        },
        {
          id: 'theme',
          label: 'Theme',
          callback: this.setTheme
        }
      ]
    }
  },
  methods: {
    ...mapActions('sites', { activateSite: 'ACTIVATE_SITE' }),
    ...mapActions('sites', ['FETCH_SITES']),
    ...mapActions('users', [
      'FETCH_USER',
      'FETCH_USER_SITES_ASSOCIATION',
      'UPDATE_USER'
    ]),
    ...mapActions('system', ['FETCH_SYSTEM_SETTINGS']),
    ...mapActions(['FETCH_TIMEZONES', 'SET_THEME']),
    ...mapActions('sensors', [
      'FETCH_SENTRIES',
      'FETCH_RADARS',
      'FETCH_CANNONS',
      'FETCH_GPS_COMPASSES',
      'FETCH_DISCOVAIR_SENSORS'
    ]),
    ...mapActions('cameras', ['FETCH_CAMERAS']),
    ...mapActions('rf_sensors', ['FETCH_RF_SENSORS']),
    ...mapActions('sentries', ['FETCH_SENTRIES']),
    ...mapActions('maps', ['setActiveMapLayers', 'setMapLayer']),
    ...mapActions('site_warnings', ['FETCH_SITE_WARNINGS']),
    ...mapActions('site_markers', ['FETCH_SITE_MARKERS']),
    ...mapActions('agl_mask', ['fetchAGLMaskTotal']),
    ...mapActions('sensors', ['FETCH_ZMASK_TOTAL']),
    ...mapActions('zones', { fetchZones: 'FETCH_ZONES' }),
    ...mapActions('system', ['setDataLoadedState']),
    ...mapActions('rf_filters', ['FETCH_RF_FILTERS', 'clearFilters']),
    ...mapActions('detection', ['resetDetections']),
    async fetchSites(): Promise<void> {
      if (Object.keys(this.sitesSet).length === 0) {
        await this.FETCH_SITES()
      }
    },
    async fetchUserData(): Promise<void> {
      await this.FETCH_USER_SITES_ASSOCIATION(this.user)
    },
    async fetchSystemInfo(): Promise<void> {
      await this.FETCH_SYSTEM_INFO()
    },
    async fetchTimezones(): Promise<void> {
      if (this.timezonesList.length === 0) {
        await this.FETCH_TIMEZONES()
      }
    },
    activateFirstSite(): void {
      if (Object.keys(this.sitesSet).length > 0) {
        const firstSiteId = Object.keys(this.sitesSet)[0]
        this.activateSite(firstSiteId)
      }
    },
    async fetchSentries(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_SENTRIES()
    },
    async fetchRfSensors(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_RF_SENSORS(this.activeSiteId)
    },
    async fetchRadars(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_RADARS()
    },
    async fetchCameras(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_CAMERAS(this.activeSiteId)
    },
    async fetchCannons(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_CANNONS()
    },
    async fetchGpsCompasses(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_GPS_COMPASSES()
    },
    async fetchDiscovairs(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_DISCOVAIR_SENSORS()
    },
    async setTheme(): Promise<void> {
      const theme = this.user.theme_name || 'DroneShield'
      await this.SET_THEME({ name: theme })
    },
    sitesExists(siteId: number): boolean {
      return Object.values(this.sitesSet).some((s: ISite) => s.id === siteId)
    },
    async setupUser(): Promise<void> {
      if (Object.keys(this.user).length === 0) {
        await this.FETCH_USER()
        if (this.siteCount === 0) return

        if (
          this.user.default_site_id &&
          this.sitesExists(this.user.default_site_id)
        ) {
          this.activateSite(this.user.default_site_id)
        } else {
          this.activateFirstSite()
        }
      }
      await this.fetchZoneData()
    },
    async fetchZoneData(): Promise<void> {
      if (this.siteCount === 0) return
      await this.fetchZones(this.activeSiteId)
    },
    async initializeActiveMapLayers(): Promise<void> {
      const mapLayers = this.user?.settings?.mapLayers
      if (mapLayers) {
        const map = this.user?.settings?.map || mapLayers[0]
        await this.setActiveMapLayers(mapLayers)
        await this.setMapLayer(map)
      } else {
        const defaultMaps = [
          'ESRI World Imagery Topology',
          'ESRI World Imagery Satellite'
        ]
        const user = Object.assign({ settings: {} }, this.user)
        user.settings.mapLayers = defaultMaps
        user.settings.map = defaultMaps[0]
        await this.UPDATE_USER(user)
        await this.setActiveMapLayers(defaultMaps)
        await this.setMapLayer(user.settings.map)
      }
    },
    async fetchSiteWarnings(): Promise<void> {
      if (this.timezonesList.length === 0) {
        await this.FETCH_TIMEZONES()
      }
      if (this.siteCount === 0) return
      await this.FETCH_SITE_WARNINGS({
        site_id: parseInt(this.activeSiteId),
        last_2_days: false,
        read: false,
        timezone: this.activeSite.timezone
      }) // fetch no read warnings
      // TODO: remove me and call me only when accessing the WarningsMenu.vue component and do not save the history in there (can't do until all warnings are in the API)
      await this.FETCH_SITE_WARNINGS({
        site_id: parseInt(this.activeSiteId),
        last_2_days: true,
        read: true,
        timezone: this.activeSite.timezone
      }) // fetch last 2 days read warnings
    },
    async fetchSiteMarkers(): Promise<void> {
      if (this.siteCount === 0) return
      await this.FETCH_SITE_MARKERS(this.activeSiteId)
    },
    async fetchZMaskTotal(): Promise<void> {
      if (this.siteCount === 0) return
      if (Object.values(this.radarsSet).length > 0) {
        await this.FETCH_ZMASK_TOTAL(this.activeSiteId)
      }
    },
    async fetchAglMasks(): Promise<void> {
      if (this.siteCount === 0) return
      if (Object.values(this.radarsSet).length > 0) {
        await this.fetchAGLMaskTotal(this.activeSiteId)
      }
    },
    async fetchRfFilters(): Promise<void> {
      if (this.siteCount === 0) return
      this.clearFilters()
      const rfSensors = {...Object.values(this.rfSensorsSet), ...Object.values(this.dsxSensorsSet) } as IRfSensor[]
      if (rfSensors.length > 0) {
        rfSensors.forEach(async rfSensor => {
          await this.FETCH_RF_FILTERS({
            site_id: this.activeSiteId,
            sensor_id: rfSensor.id
          })
        })
      }
    }
  }
})
