




import Vue from 'vue'
import DataLoader from '../components/DataLoader/DataLoader.vue'
import { mapActions, mapState } from 'vuex'
import socketManager from '@/plugins/websocket'

const props = {
  id: {
    type: [Number, String],
    default: null
  }
}

export default Vue.extend({
  name: 'DataLoaderView',
  props,
  components: { DataLoader },
  data() {
    return {
      ready: false
    }
  },
  async mounted() {
    if (Object.keys(this.sitesSet).length === 0) {
      await this.fetchSites()
    }
    if (this.dataSocket?.connected) {
      if (this.dataSocket?.subscribed && this.activeSiteId) {
        this.dataSocket?.unsubscribe('SitesChannel', this.activeSiteId)
      }
      this.activateSite(this.id ?? this.firstSiteId)
    }

    this.ready = true
  },
  computed: {
    ...mapState('system', ['dataWebsocket', 'alertsWebsocket']),
    ...mapState('sites', ['activeSiteId', 'sitesSet']),
    firstSiteId(): number {
      return parseInt(Object.keys(this.sitesSet)[0]) || null
    },
    dataSocket(): socketManager {
      return this.dataWebsocket
    },
    dataSocketConnected(): boolean {
      return this.dataSocket?.connected || false
    },
    alertsSocket(): socketManager {
      return this.alertsWebsocket
    }
  },
  methods: {
    ...mapActions('system', ['setDataLoadedState']),
    ...mapActions('sites', {
      activateSite: 'ACTIVATE_SITE',
      fetchSites: 'FETCH_SITES'
    }),
    onComplete(): void {
      this.subscribeSitesChannel()
      this.subscribeAlertsChannel()
      // does this need to depend on successful socket subscriptions?
      this.setDataLoadedState(true)
      this.$router.replace({ name: 'Home' })
    },
    subscribeSitesChannel(): void {
      try {
        this.dataSocket.subscribe('SitesChannel', this.activeSiteId)
      }
      catch (e) {
        setTimeout(this.subscribeSitesChannel, 2000)
      }
    },
    subscribeAlertsChannel(): void {
      try {
        this.alertsSocket.subscribe('AlertsChannel')
      }
      catch (e) {
        setTimeout(this.subscribeAlertsChannel, 2000)
      }
    }
  },
  watch: {
    dataSocketConnected: {
      handler: function(val) {
        if (val) {
          if (this.dataSocket?.subscribed && this.activeSiteId) {
            this.dataSocket?.unsubscribe('SitesChannel', this.activeSiteId)
          }
          this.activateSite(this.id ?? this.firstSiteId)
        }
      }
    }
  }
})
